<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'GuardGroups' }"
              >群組保護</b-breadcrumb-item
            >
            <b-breadcrumb-item :to="{ name: 'GroupProtectGuards' }"
              >衛兵狀態列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>衛兵明細資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <h4 class="font-weight-bold">衛兵明細資料</h4>

      <div class="row">
        <div class="col-6">
          姓名：{{ customer.display_name }}
          <br />
          {{ customer.display_status }}
        </div>
        <div class="col-6">ID：{{ customer.id }}</div>
      </div>
      <hr />
      <div class="row">
        <div class="col-3">
          <b-img-lazy
            :src="customer.avatar_url"
            fluid
          ></b-img-lazy>
        </div>
        <div class="col-6">
          <div>外部ID：{{ customer.outer_code }}</div>
          <div>手機：{{ customer.mobile_phone }}</div>
          <div>E-mail：{{ customer.email }}</div>
          <div>生日：{{customer.birthday && format(new Date(customer.birthday), "yyyy-MM-dd HH:mm") }}</div>
          <div>性別：{{ customer.sexual }}</div>
          <div>好友：{{ customer.is_friend ? "是" : "否" }}</div>
          <div>加入時間：{{customer.join_datetime && format(new Date(customer.join_datetime), "yyyy-MM-dd HH:mm") }}</div>
          <div v-if="customer.leave_datetime">
            離開時間：{{customer.leave_datetime &&  format(new Date(customer.leave_datetime), "yyyy-MM-dd HH:mm") }}
          </div>
          <div>建立時間：{{customer.created_at &&  format(new Date(customer.created_at), "yyyy-MM-dd HH:mm") }}</div>
          <div>更新時間：{{customer.updated_at &&  format(new Date(customer.updated_at), "yyyy-MM-dd HH:mm") }}</div>
        </div>
      </div>

      <hr />

      <div class="d-flex justify-content-between">
        <h4 class="font-weight-bold">所屬群組</h4>

        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              placeholder="搜尋群組"
              v-model="keyword"
              v-on:keyup.enter="fetchGroups()"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="fetchGroups()"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table responsive striped hover :items="groups" :fields="fields">
        <template #cell(name)="data">
          <div class="mb-1">
            {{ data.item.name }}
          </div>
          <div>
            {{ data.item.group_number }}
          </div>
        </template>
        <template #cell(picture_url)="data">
          <b-img-lazy
            v-bind="mainProps"
            v-show="!!data.item.picture_url"
            rounded
            :src="data.item.picture_url"
          ></b-img-lazy>
        </template>
        <template #cell(status)="data">
          <i
            :class="`fa fa-circle h2 text-${statusEnum(data.item.status)}`"
          ></i>
        </template>

        <template #cell(actions)="data">
          <b-button
            :variant="data.item.status == 0 ? `primary` : `danger`"
            class="ml-2"
            size="md"
          >
            {{ data.item.status == 0 ? `設定` : `重設` }}</b-button
          >
        </template>
      </b-table>

    </b-card>
  </div>
</template>

<script>
import format from "date-fns/format";
import guardApi from "../../../apis/guard";

export default {
  data() {
    return {
      customer: {},
      groups: [],
      text: "{customers.data}",
      showLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "status",
          label: "群組狀態",
        },
        {
          key: "picture_url",
          label: "群組照片",
        },
        {
          key: "name",
          label: "群組名稱/ID",
        },
      ],
      keyword:null,
    };
  },
  created() {
    this.getCustomer();
    this.fetchGroups();
  },
  methods: {
    format,
    async fetchGroups() {
      this.showLoading = true;

      try {
        // const params = {
        //   page: page || this.currentPage,
        // };
        // if (this.keyword) params.keyword = this.keyword;
        // params.status = "0,1,2";

        const { data } = await guardApi.getGroupsOfGuard( this.$route.params.guardID);

        this.groups = data.data;

      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    async getCustomer() {
      try {
        let { data } = await guardApi.getGuardDetail(
          this.$route.params.guardID
        );

        this.customer = data.data;


        // console.log(data);
        // this.customer = {
        //   ...data,
        //   join_datetime:
        //     data.join_datetime &&
        //     format(new Date(data.join_datetime), "yyyy-MM-dd HH:mm"),
        //   leave_datetime:
        //     data.leave_datetime &&
        //     format(new Date(data.leave_datetime), "yyyy-MM-dd HH:mm"),
        //   created_at: format(new Date(data.created_at), "yyyy-MM-dd HH:mm"),
        //   updated_at: format(new Date(data.updated_at), "yyyy-MM-dd HH:mm"),
        // };
        // this.text = JSON.stringify(data.data);
      } catch (e) {
        console.log(e);
      }
    },
    statusEnum(status) {
      if (status === 0) {
        return "danger";
      }
      if (status === 1) {
        return "success";
      }
      if (status === 2) {
        return "dark";
      }
    },
  },
};
</script>
